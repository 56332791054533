<template>
  <super-admin-page-card pageName="Copy Reports" iconClass="fa fa-clone fa-lg">
    <div>
      <template slot="title">
        <h6 class="tab-title font-weight-bold">
          Copy Reports
        </h6>
      </template>

      <v-wait for="loadingNonNavigatorReports">
        <template slot="waiting">
          <content-placeholders :rounded="true" class="pt-4">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>

        <div>
          <div class="row px-2">
            <div class="col-sm-12 text-left">
              <small>
                <b>
                  <sup class="">* </sup>
                  NOTE:
                </b>
                Copy will not update existing report, it will create a duplicate
                copy for the new group.
              </small>
            </div>
          </div>
        </div>
        <div class="p-3">
          <div class="row">
            <div class="col-sm-6">
              <div>
                <b-form-group
                  label="Report"
                  label-for="report"
                  :label-cols="3"
                  :horizontal="true"
                  class=""
                >
                  <multiselect
                    v-model="selectedReport"
                    :options="nonNavigatorReports"
                    :multiple="false"
                    :close-on-select="true"
                    :showLabels="false"
                    track-by="id"
                    label="displayName"
                    placeholder="Select report to share"
                    :class="[
                      'simple-select group-select highlight-group-title form-control rounded'
                    ]"
                    @select="fetchReportGroupsData"
                  >
                  </multiselect>
                </b-form-group>

                <b-form-group
                  label="Group"
                  label-for="group"
                  :label-cols="3"
                  :horizontal="true"
                  class=""
                >
                  <multiselect
                    v-model="selectedGroup"
                    :options="availableGroups"
                    :multiple="false"
                    :close-on-select="true"
                    :showLabels="false"
                    :loading="fetchingGroups"
                    track-by="id"
                    label="displayName"
                    placeholder="Select a group to share with"
                    :class="[
                      'simple-select group-select highlight-group-title form-control rounded'
                    ]"
                  >
                  </multiselect>
                </b-form-group>
              </div>
            </div>

            <div class="col-sm-6 pl-5" v-if="selectedReport">
              <div class="row" v-if="selectedReport.fi_type == 'mbc'">
                <b-card
                  class="std-border mb-3 rounded card--report-schedule"
                  body-class="p-2 schedule-body"
                  header-class="p-2 schedule-header"
                >
                  <div>
                    <b>{{ selectedReport.name }}</b> is an <b>MBC</b> report.
                    Please make sure the shared group has appropriate access to
                    view the selected report.
                  </div>
                </b-card>
              </div>
              <div class="row">
                <b-card
                  class=" col-sm-12 px-0 std-border mb-3 rounded card--report-schedule"
                  body-class="p-2 schedule-body"
                  header-class="p-2 schedule-header"
                >
                  <div slot="header">
                    <div class="row fit-to-center">
                      <div class="col-sm-12">
                        <b> Shared Groups </b>
                      </div>
                    </div>
                  </div>

                  <v-wait for="loadingReportGroups">
                    <template slot="waiting">
                      <content-placeholders :rounded="true" class="pt-4">
                        <content-placeholders-text
                          :lines="5"
                        ></content-placeholders-text>
                      </content-placeholders>
                    </template>
                    <div v-if="sharedGroups.length">
                      <p>
                        <b>{{ selectedReport.name }}</b> currently is shared
                        among
                      </p>
                      <b-list-group>
                        <b-list-group-item
                          v-for="(group, index) in sharedGroups"
                          :key="index"
                        >
                          {{ group.name }} ({{ group.id }})
                        </b-list-group-item>
                      </b-list-group>
                    </div>
                    <div v-else>
                      <p>
                        <b>{{ selectedReport.name }}</b> currently is not shared
                        among it's group.
                      </p>
                    </div>
                  </v-wait>
                </b-card>
              </div>
            </div>
          </div>

          <div class="row pt-3">
            <div class="col-6 d-flex justify-content-center">
              <div class="px-1">
                <button
                  type="button"
                  class="btn fw-btn btn-secondary rounded"
                  @click="resetReportShare"
                >
                  Reset
                </button>
              </div>
              <div class="px-1">
                <button
                  type="button"
                  class="btn fw-btn btn-primary rounded"
                  @click="copyReport"
                >
                  Copy
                </button>
              </div>
            </div>
          </div>
        </div>
      </v-wait>
    </div>
  </super-admin-page-card>
</template>

<script>
// ui components
import Multiselect from 'vue-multiselect'
// api
import selfServeReportsAPI from '@/api/finapps/super_admin/self_serve_reports'

export default {
  name: 'SuperAdminCopyReports',
  components: {
    Multiselect
  },
  data() {
    return {
      nonNavigatorReports: [],

      fetchingGroups: false,
      selectedReport: null,
      selectedGroup: null,
      sharedGroups: [],
      availableGroups: []
    }
  },
  created() {
    this.loadNonNavigatorReports()
  },
  computed: {},
  methods: {
    loadNonNavigatorReports() {
      this.$wait.start('loadingNonNavigatorReports')

      selfServeReportsAPI.nonNavigatorReports().then(res => {
        this.nonNavigatorReports = res.non_navigator_reports
        this.nonNavigatorReports.forEach(rep => {
          rep['displayName'] = rep.name + ' - [User:' + rep.user_id + ']'
        })
        this.$wait.end('loadingNonNavigatorReports')
      })
    },

    fetchReportGroupsData(report) {
      this.fetchingGroups = true
      this.$wait.start('loadingReportGroups')

      selfServeReportsAPI.groupDetails(report.id).then(res => {
        this.sharedGroups = res.shared_groups
        this.availableGroups = res.sharable_groups
        this.availableGroups.forEach(grp => {
          grp['displayName'] = grp.name + ' [' + grp.id + ']'
        })
        this.fetchingGroups = false
        this.$wait.end('loadingReportGroups')
      })
    },
    copyReport() {
      selfServeReportsAPI
        .copyReport(this.selectedReport.id, this.selectedGroup.id)
        .then(
          () => {
            this.$toasted.global.action_success('Report copied successfully.')
            this.resetReportShare()
            this.loadNonNavigatorReports()
          },
          () => {
            this.selectedGroup = null
          }
        )
    },
    resetReportShare() {
      this.selectedReport = null
      this.selectedGroup = null
      this.sharedGroups = []
      this.availableGroups = []
    }
  }
}
</script>
<style lang="scss" scoped></style>
