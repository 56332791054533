import Vue from 'vue'
import axios from 'axios'

export default {
  reports() {
    return axios
      .get('/api/super_admin/self_serve_reports/navigator_reports', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  nonNavigatorReports() {
    return axios
      .get('/api/super_admin/self_serve_reports/non_navigator_reports', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  groupDetails(reportID) {
    return axios
      .get(`/api/super_admin/self_serve_reports/${reportID}/group_details`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  copyReport(reportID, groupID) {
    return axios
      .get(`/api/super_admin/self_serve_reports/${reportID}/copy_report`, {
        params: {
          group_id: groupID
        },
        handleErrors: true
      })
      .then(
        res => {
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show(
              'Validation failed. Report already exists for selected group.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
          return Promise.reject(error)
        }
      )
  },
  updateAccess(reportID, isPublic) {
    return axios
      .put(
        `/api/super_admin/self_serve_reports/${reportID}`,
        {
          is_public: isPublic
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  },
  updateCategory(reportID, category) {
    return axios
      .put(
        `/api/super_admin/self_serve_reports/${reportID}/update_category`,
        {
          category: category
        },
        {
          handleErrors: true
        }
      )
      .then(
        res => {
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show(
              'Validation failed. Client Single click default report.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
          return Promise.reject('validation failed')
        }
      )
  },
  updateOrder(publicReports) {
    return axios
      .put(
        '/api/super_admin/self_serve_reports/update_order',
        {
          self_serve_reports: publicReports.map(pr => ({
            id: pr.id,
            report_order: pr.report_order
          }))
        },
        { handleErrors: true }
      )
      .then(() => {})
  }
}
